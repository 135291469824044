import { API_ROUTES } from '../../constants/apiRoutes';
import { defaultQueryData } from '../../constants/common';
import api from '../../utils/api';
import { formatCurrency, getApiRoute } from '../../utils/helper';

export const myPropertiesApi = async (queryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_PROPERTIES, {
      params: { ...queryData, filter: 'myProperties' }
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRealtorBrokerageOfficeApi = async (queryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_USER_BROKERAGE_OFFICE, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const validatePropertyApi = async (addressData) => {
  try {
    const payload = {
      address: addressData.address,
      city: addressData.city,
      state: addressData.state,
      ...(addressData.id && { id: addressData.id })
    };

    const response = await api.post(API_ROUTES.VALIDATE_ADDRESS, payload, {
      validateStatus: function () {
        return true;
      }
    });

    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addPropertyTagRequestApi = async (propertyId) => {
  try {
    const addPropertyTagRequestApiRoute = getApiRoute(
      API_ROUTES.ADD_PROPERTY_TAG_REQUEST,
      propertyId
    );
    const response = await api.put(addPropertyTagRequestApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPropertyHistoryApi = async ({ queryData, propertyId }) => {
  try {
    const getPropertyHistoryApiRoute = getApiRoute(API_ROUTES.GET_PROPERTY_HISTORY, propertyId);
    const response = await api.get(getPropertyHistoryApiRoute, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPropertyTagRequestApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_PROPERTY_TAG_REQUEST, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const propertyTagRequestActionApi = async ({ propertyId, tagAction }) => {
  try {
    const propertyTagRequestActionRoute = getApiRoute(
      API_ROUTES.PROPERTY_TAG_REQUEST_ACTION,
      propertyId
    );
    const response = await api.post(propertyTagRequestActionRoute, {
      action: tagAction
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getRelationshipHistoryApi = async ({ queryData, propertyId }) => {
  try {
    const getRelationshipHistoryApiRoute = getApiRoute(
      API_ROUTES.GET_RELATIONSHIP_HISTORY,
      propertyId
    );
    const response = await api.get(getRelationshipHistoryApiRoute, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const getPropertyNotificationApi = async (queryData = defaultQueryData) => {
  try {
    const response = await api.get(API_ROUTES.GET_PROPERTY_NOTIFICATIONS, {
      params: queryData
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const propertyTagNotificationActionApi = async ({ propertyId, action }) => {
  try {
    const tagActionApiRoute = getApiRoute(API_ROUTES.REASSIGN_PROPERTY_TAG_ACTION, propertyId);
    const response = await api.post(tagActionApiRoute, {
      action
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const addEmailBlastApi = async (emailBlastData) => {
  try {
    const response = await api.post(API_ROUTES.CREATE_EMAIL_BLAST, {
      brokerageOfficeId: emailBlastData.brokerageOffice,
      subjectLine: emailBlastData.subjectLine,
      description: emailBlastData.newDescription || emailBlastData.editorDescription,
      noOfBedrooms: emailBlastData.noOfBedrooms,
      noOfBathrooms: emailBlastData.noOfBathrooms,
      garageSpace: emailBlastData.garageSpace,
      squreFootage: emailBlastData.squreFootage,
      buildYear: emailBlastData.buildYear,
      afterRepairPrice: formatCurrency(emailBlastData.afterRepairPrice).toString(),
      askingPrice: formatCurrency(emailBlastData.askingPrice).toString(),
      estimateEquityCapture: formatCurrency(emailBlastData.estimateEquityCapture).toString(),
      returnOnEquityCapture: emailBlastData.returnOnEquityCapture,
      estimateCashPerMonth: formatCurrency(emailBlastData.estimateCashPerMonth).toString(),
      estimateCashOnCashReturn: emailBlastData.estimateCashOnCashReturn,
      estimateOutOfPocketType: Number(emailBlastData.estimateOutOfPocketType),
      estimateOutOfPocket: formatCurrency(emailBlastData.estimateOutOfPocket).toString(),
      uploadedFiles: emailBlastData.uploadedFiles,
      isSendToMe: emailBlastData.isSendToMe,
      wholesaleDisclaimer: Number(emailBlastData.wholesaleDisclaimer),
      draftEmailBlastId: emailBlastData.draftEmailBlastId || '',
      brokerageOfficeType: emailBlastData.brokerageOfficeType
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const draftEmailBlastApi = async (emailBlastData) => {
  try {
    const response = await api.post(API_ROUTES.DRAFT_EMAIL_BLAST, {
      brokerageOfficeId: emailBlastData.brokerageOffice,
      subjectLine: emailBlastData.subjectLine,
      description: emailBlastData.newDescription || emailBlastData.editorDescription,
      noOfBedrooms: emailBlastData.noOfBedrooms,
      noOfBathrooms: emailBlastData.noOfBathrooms,
      garageSpace: emailBlastData.garageSpace,
      squreFootage: emailBlastData.squreFootage,
      buildYear: emailBlastData.buildYear,
      afterRepairPrice: formatCurrency(emailBlastData.afterRepairPrice).toString(),
      askingPrice: formatCurrency(emailBlastData.askingPrice).toString(),
      estimateEquityCapture: formatCurrency(emailBlastData.estimateEquityCapture).toString(),
      returnOnEquityCapture: emailBlastData.returnOnEquityCapture,
      estimateCashPerMonth: formatCurrency(emailBlastData.estimateCashPerMonth).toString(),
      estimateCashOnCashReturn: emailBlastData.estimateCashOnCashReturn,
      estimateOutOfPocketType: Number(emailBlastData.estimateOutOfPocketType),
      estimateOutOfPocket: formatCurrency(emailBlastData.estimateOutOfPocket).toString(),
      uploadedFiles: emailBlastData.uploadedFiles,
      isSendToMe: emailBlastData.isSendToMe,
      wholesaleDisclaimer: Number(emailBlastData.wholesaleDisclaimer),
      brokerageOfficeType: emailBlastData.brokerageOfficeType
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const editDraftEmailBlastApi = async ({ emailBlastId, emailBlastData }) => {
  try {
    const response = await api.post(API_ROUTES.DRAFT_EMAIL_BLAST, {
      brokerageOfficeId: emailBlastData.brokerageOffice,
      subjectLine: emailBlastData.subjectLine,
      description: emailBlastData.newDescription || emailBlastData.editorDescription,
      noOfBedrooms: emailBlastData.noOfBedrooms,
      noOfBathrooms: emailBlastData.noOfBathrooms,
      garageSpace: emailBlastData.garageSpace,
      squreFootage: emailBlastData.squreFootage,
      buildYear: emailBlastData.buildYear,
      afterRepairPrice: formatCurrency(emailBlastData.afterRepairPrice).toString(),
      askingPrice: formatCurrency(emailBlastData.askingPrice).toString(),
      estimateEquityCapture: formatCurrency(emailBlastData.estimateEquityCapture).toString(),
      returnOnEquityCapture: emailBlastData.returnOnEquityCapture,
      estimateCashPerMonth: formatCurrency(emailBlastData.estimateCashPerMonth).toString(),
      estimateCashOnCashReturn: emailBlastData.estimateCashOnCashReturn,
      estimateOutOfPocketType: Number(emailBlastData.estimateOutOfPocketType),
      estimateOutOfPocket: formatCurrency(emailBlastData.estimateOutOfPocket).toString(),
      uploadedFiles: emailBlastData.uploadedFiles,
      isSendToMe: emailBlastData.isSendToMe,
      wholesaleDisclaimer: Number(emailBlastData.wholesaleDisclaimer),
      emailBlastId: emailBlastId,
      brokerageOfficeType: emailBlastData.brokerageOfficeType
    });
    return response;
  } catch (error) {
    throw new Error(error);
  }
};

export const deleteDraftEmailBlastApi = async (emailBlastData) => {
  try {
    const deleteDraftEmailBlastApiRoute = getApiRoute(
      API_ROUTES.DELETE_DRAFT_EMAIL_BLAST,
      emailBlastData.id
    );
    const response = await api.delete(deleteDraftEmailBlastApiRoute);
    return response;
  } catch (error) {
    throw new Error(error);
  }
};
